import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header/Header"
import Navbar from "../components/navbar/navbar"
import "./gift.scss"

import { renderRichText } from "gatsby-source-contentful/rich-text"
import { options } from "../utils/queryUtils"

export const query = graphql`
  query gift {
    contentfulGift {
      info {
        raw
      }
      title
      giftbox {
        title
        file {
          url
        }
      }
      footer {
        title
        file {
          url
        }
      }
    }
  }
`
const Gift = ({ data }) => {
  return (
    <>
      <Header />
      <Navbar />
      <h2 className="page-title">Wedding Gifts</h2>

      <div className="image-container">
        <img
          className="giftbox"
          src={data?.contentfulGift.giftbox.file.url}
          alt="giftbox-drawing"
        />
      </div>
      <div className="text-container">
        {renderRichText(data?.contentfulGift.info, options)}
      </div>
      <div className="image-container">
        <img
          className="image-container footer"
          src={data?.contentfulGift.footer.file.url}
          alt="footer of flowers"
        />
      </div>
    </>
  )
}

export default Gift
